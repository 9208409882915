<template>
    <div>
        <h2 class="border-bottom pb-2 mb-3"><i class="fa fa-signal"></i> E-Campaign Check Reporting</h2>

        <div style="background-color: white; padding:2%;">
            <div class="row mb-3">
                <div class="col-12 text-right">
                    <div class="form-inline">
                        <b-input-group class="mr-4">
                            <b-form-input v-model="filterType"></b-form-input>
                            <template #append>
                                <b-dropdown text="Filter" variant="success">
                                    <b-dropdown-item @click="setFilter('today')">Today</b-dropdown-item>
                                    <b-dropdown-item @click="setFilter('yesterday')">Yesterday</b-dropdown-item>
                                    <b-dropdown-item @click="setFilter('2daysago')">2 Days Ago</b-dropdown-item>
                                </b-dropdown>
                                <b-button style="background-color: #383838" @click="filterECampaigns"><i class="fa fa-search"></i> Search</b-button>
                            </template>
                        </b-input-group>
                        <button type="button" class="btn btn-primary mr-1" @click="check"><i class="fa fa-refresh" title="Check for Updates"></i> Check for Updates</button>
                        <button type="button" class="btn btn-success mr-1" v-if="canExport" @click="exportFile"><i class="fa fa-download" title="Export to File"></i> Export to File</button>
                        <button type="button" class="btn btn-success mr-1" v-if="canExport" @click="thirdPartyExport"><i class="fa fa-download" title="Export to File"></i> Third Party Export</button>
                    </div>
                </div>
            </div>
            <div class="row mb-12" v-if="reportLoading && !reportLoaded">
                <div class="col-12">
                    <div class="card">
                        <div class="card-body text-center">
                            <b-spinner style="width: 3rem; height: 3rem;" type="grow" label="Processing......" variant="primary"></b-spinner>
                            <h4>Processing..............</h4>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row mb-12" v-if="reportLoaded && !reportLoading">
                <div class="col-12">
                    <div class="card">
                        <div class="card-body text-center">
                            <div class="row mb-12">
                                <div class="col-12 m-table">
                                    <h3>Missing Campaigns ({{ this.missedTotal }}) Missed Clicks ({{ this.missedClicks }})</h3>
                                    <b-table head-variant="dark" striped hover :fields="fields" :items="missing"></b-table>
                                </div>
                                <!--<div class="col-5 m-table">
                                    <h3>Missing Campaigns ({{ this.missedTotal }})</h3>
                                    <b-table head-variant="dark" striped hover :items="missing"></b-table>
                                </div>
                                <div class="col-5 offset-2 m-table">
                                    <h3>Present Campaigns ({{ this.presentTotal }})</h3>
                                    <b-table head-variant="dark" striped hover :items="present"></b-table>
                                </div>-->
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>


    </div>
</template>

<script>
    import axios from 'axios'

    export default {
        name: 'CampaignReport',
        props:['user'],
        components:{
        },
        data: function () {
            return {
                action:'',
                missing: [],
                present: [],
                fields:['original','normalized','formatted','hits'],
                fileName: "data.txt",
                fileData: "",
                fileDataTp: "",
                reportLoading: false,
                reportLoaded: false,
                searching: false,
                filterType: 'Today'
            }
        },
        computed:{
            missedTotal: function(){
                return Number(this.missing.length).toLocaleString();
            },
            presentTotal: function(){
                return Number(this.present.length).toLocaleString();
            },
            missedClicks: function(){
                let total = 0;
                for(let i in this.missing){
                    total += this.missing[i].hits;
                }
                return Number(total).toLocaleString();
            },
            canExport: function(){
                return (this.missing.length > 0);
            }
        },
        created: function(){
            this.load();
            window.addEventListener("keydown",this.windowListener);
        },
        destroyed: function() {
            window.removeEventListener("keydown",this.windowListener);
        },
        methods:{
            windowListener: function(e){
                if(e.keyCode === 13 && !this.searching){
                    this.check();
                }
            },
            check: function(){
                if(this.searching) return;
                this.searching = true;
                const request = {
                    'apikey': this.$root.apikey,
                };

                this.reportLoading = true;
                this.reportLoaded = false;
                this.filterType = "Today";
                this.missing = [];
                this.present = [];

                axios.get(`${this.$root.serverUrl}/admin/reports/campaign/e-campaign`,{params:request}).then(function(){
                    /*//Store the stats
                    this.missing = resp.data.missing;
                    //this.present = resp.data.present;
                    this.fileName = `e_campaign_export ${new Date()}`;
                    this.fileName = this.fileName.replace(/ /g,"_");
                    this.fileName = this.fileName.replace(/:/g,"_");
                    //this.createExportData();
                    this.createExportDataCsv();
                    this.createThirdPartyExportData();
                    this.reportLoading = false;
                    this.reportLoaded = true;
                    this.searching = false;*/
                    this.searching = false;
                    this.filterECampaigns();
                }.bind(this)).catch(() => {
                    // eslint-disable-next-line no-undef
                    swal("Oops!","An error occurred on the server. Try refreshing the page.","error");
                    this.reportLoading = false;
                    this.reportLoaded = true;
                    this.searching = false;
                });
            },
            createExportData: function(){
                this.fileData = `
========================================================================================================================
                       MISSING CAMPAIGNS (${this.missedTotal}) MISSED CLICKS (${this.missedClicks})
========================================================================================================================
`;
                if(this.missing.length > 0){
                    for(let i in this.missing){
                        let line = `\t`;
                        let buffer = '';
                        buffer  = 50 - this.missing[i].original.length;
                        line += `${this.missing[i].original}`;
                        for(let i=0; i<buffer; i++){
                            line += ' ';
                        }
                        buffer = 30 - this.missing[i].normalized.length;
                        line += `${this.missing[i].normalized}`;
                        for(let i=0; i<buffer; i++){
                            line += ' ';
                        }
                        buffer = 30 - this.missing[i].formatted.length;
                        line += `${this.missing[i].formatted}`;
                        for(let i=0; i<buffer; i++){
                            line += ' ';
                        }
                        line += `${this.missing[i].hits}`;
                        //this.fileData += `\t${this.missing[i].original}\t\t\t\t${this.missing[i].formatted}\t\t\t\t${this.missing[i].hits}\n`;
                        //this.fileData += `\t${this.missing[i].original}\n`;
                        this.fileData += `${line}\n`;

                    }
                }

                /*this.fileData += `
========================================================================================================================
                                        PRESENT CAMPAIGNS (${this.presentTotal})
========================================================================================================================
`;
                if(this.present.length > 0){
                    for(let i in this.present){
                        //this.fileData += `\t${this.present[i].original}\t\t\t\t${this.present[i].formatted}\t\t\t\t${this.present[i].hits}\n`;
                        this.fileData += `\t${this.present[i].original}\n`;
                    }
                }*/

                this.fileData = encodeURIComponent(this.fileData);
                this.fileData = "data:text/plain;charset=utf-8," + this.fileData;
            },
            createExportDataCsv: async function(){
                this.fileData = `original,normalized,formatted,hits\n`;
                if(this.missing.length > 0){
                    for(let i in this.missing){
                        let line = ``;
                        line += `${this.missing[i].original},`;
                        line += `${this.missing[i].normalized},`;
                        line += `${this.missing[i].formatted},`;
                        line += `${this.missing[i].hits}`;
                        this.fileData += `${line}\n`;
                    }
                }
                this.fileData = encodeURIComponent(this.fileData);
                this.fileData = "data:text/plain;charset=utf-8," + this.fileData;
            },
            createThirdPartyExportData: async function(){
                this.fileDataTp = "";
                let data = "";
                if(this.missing.length > 0){
                    for(let i in this.missing){
                        data += `<br>${this.missing[i].original}`;
                    }
                }

                data =
                    `<html>
                        <head></head>
                        <body>
                            ${data}
                        </body>
                    </html>`;
                //this.fileData = encodeURIComponent(this.fileData);
                this.fileDataTp = "data:text/html;charset=utf-8," + data;
            },
            exportFile: function(){
                const element = document.createElement('a');
                element.setAttribute('href', this.fileData);
                element.setAttribute('download', `${this.fileName}.csv`);
                element.setAttribute('target','_blank');
                element.style.display = 'none';
                document.body.appendChild(element);
                element.click();
                document.body.removeChild(element);
            },
            thirdPartyExport: function(){
                const element = document.createElement('a');
                element.setAttribute('href', this.fileDataTp);
                element.setAttribute('download', `${this.fileName}.html`);
                element.setAttribute('target','_blank');
                element.style.display = 'none';
                document.body.appendChild(element);
                element.click();
                document.body.removeChild(element);
            },
            load: function(){
                this.$root.preloader = false;
            },
            setFilter: function(filter){
                if(this.searching) return;
                switch (filter){
                    case 'today': { this.filterType = "Today"; break; }
                    case 'yesterday': { this.filterType = "Yesterday"; break; }
                    case '2daysago': { this.filterType = "2 Days Ago"; break; }
                    default: { this.filterType = "Today"; }
                }
            },
            filterECampaigns: function(){
                if(this.searching) return;
                this.searching = true;
                const request = {
                    'apikey': this.$root.apikey,
                };

                switch (this.filterType){
                    case "Today": { request.date = "today"; break; }
                    case "Yesterday": { request.date = "yesterday"; break; }
                    case "2 Days Ago": { request.date = "2daysago"; break; }
                }

                this.reportLoading = true;
                this.reportLoaded = false;
                this.missing = [];

                axios.get(`${this.$root.serverUrl}/admin/campaigns/e-campaign-filter`,{params:request}).then(function(resp){
                    //Store the stats
                    this.missing = resp.data.missing;
                    //this.present = resp.data.present;
                    this.fileName = `e_campaign_export ${new Date()}`;
                    this.fileName = this.fileName.replace(/ /g,"_");
                    this.fileName = this.fileName.replace(/:/g,"_");
                    //this.createExportData();
                    this.createExportDataCsv();
                    this.createThirdPartyExportData();
                    this.reportLoading = false;
                    this.reportLoaded = true;
                    this.searching = false;
                }.bind(this)).catch(() => {
                    // eslint-disable-next-line no-undef
                    swal("Oops!","An error occurred on the server. Try refreshing the page.","error");
                    this.reportLoading = false;
                    this.reportLoaded = true;
                    this.searching = false;
                });
            }
        }
    }
</script>

<style scoped>
   .m-table{
       max-height: 400px;
       overflow-y: scroll;
   }
</style>
